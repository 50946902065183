<template>
  <AdminPageFrame class="w-main-container">
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:SubscriptionEdit') : trans('PAGE_TITLE:SubscriptionAdd') }}
    </PageTitle>
    <t-card class="w-full">
      <ValidationObserver ref="packageForm" v-slot="{ invalid }">
        <FieldWrapper class="mb-3" :label="trans('LABEL:Name')">
          <TextField v-model="package.name"/>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:Price')">
          <TextField rules="required" field="price" field-type="number" v-model.number="package.price"/>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:SalePrice')">
          <TextField v-model="package.salePrice"/>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:Gender')">
          <SelectField v-model="package.gender" :options="langData.genderTypesSelect" placeholder=""/>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:Age')">
          <div class="custom-slider">
            <MultiRangeSlider
              :min="ageMin"
              :max="ageMax"
              :step="1"
              :ruler="false"
              :label="false"
              ref="ageSlider"
              :minValue="package.ageMin"
              :maxValue="package.ageMax"
              @rangeStop="updateAgePackage"
            />
            <FakeTextField
                class="w-full justify-end pr-0"
                :text="package.ageMin+' - '+package.ageMax+' '+trans('LABEL:years')"
            />
          </div>
          <div class="flex items-center w-1/3"/>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:Days')">
          <TextField rules="required" v-model="package.days"/>
        </FieldWrapper>
        <div class="flex flex-row justify-end">
          <Button
            :text="trans('BUTTON:Save')"
            :loading="packagesSaving"
            @clicked="storePackageAction"
            :disabled="invalid"
          />
        </div>
      </ValidationObserver>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import FakeTextField from "@/utils/crud/components/field-types/FakeTextField.vue";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import MultiRangeSlider from "@/plugins/MultiRangeSlider.vue";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SelectField from "@/utils/crud/components/field-types/Select";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SubscriptionShow",
  components: {
    AdminPageFrame,
    Button,
    FakeTextField,
    FieldWrapper,
    MultiRangeSlider,
    PageTitle,
    SelectField,
    TextField,
  },
  data() {
    return {
      package: {},
      ageMin: 18,
      ageMax: 100,
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchShowPackageRequest(val);
        }
      }
    },
    packageLoaded(val) {
      if(val) {
        this.package = structuredClone(this.packageData)
      }
    }
  },
  computed: {
    ...mapGetters('packages', ['packagesSaving', 'packageLoaded', 'packageData']),
    ...mapGetters('partners', ['partnersSelectData']),
    ...mapGetters('lang', ['langLocale']),
  },
  methods: {
    ...mapActions('packages', ['storePackageData', 'fetchShowPackageRequest', 'updatePackageData']),
    ...mapActions('partners', ['fetchPartnersSelectRequest']),
    storePackageAction() {
      if(this.$route.params.id) {
        this.updatePackageData(this.package);
        return;
      }
      this.storePackageData(this.package);
    },
    updateAgePackage(e) {
      this.package.ageMin = e.minValue;
      this.package.ageMax = e.maxValue;
    },
  },
  mounted() {
    if(!this.package.ageMin || !this.package.ageMax) {
      this.package.ageMax = this.ageMax;
      this.package.ageMin = this.ageMin;
    }
  }
}
</script>
