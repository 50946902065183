<template>
  <div class="w-full mt-10 lg:mt-3">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: "AdminPageFrame"
}
</script>
