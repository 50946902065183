<template>
  <div class="w-full">
    <div class="w-full flex flex-row items-center font-semibold text-base lg:text-xl mb-3"
      :class="[isPageOpenedOnModal?isMobile?'pt-3':'leading-none pb-3':'',
        {
          'hover:opacity-50 cursor-pointer inline-flex gap-2': breadcrumb,
          'lg: mx-auto lg:w-main-container gap-3': registerScreen,
          'px-3':isMobile,
        }
      ]"
      @click="breadcrumb ? goTo(breadcrumb) : null"
    >
      <template v-if="breadcrumb">
        <base-icon :width="20" :height="20" name="chevronLeft">
        </base-icon>
      </template>
      <div class="w-full flex flex-col lg:flex-row gap-3 justify-start">
        <slot>

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import BaseIcon from "@/utils/icons/BaseIcon";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "PageTitle",
  components: {BaseIcon},
  props: {
    breadcrumb: [Object, String],
    chatMargins: Boolean,
    registerScreen: Boolean,
    isPageOpenedOnModal: Boolean,
  },
  data () {
    return {
      isMobile: checkIsMobileDevice()
    }
  },
  methods: {
    goTo(page) {
      router.push(page);
			this.$emit('route-change-action');
    }
  }
}
</script>

