<template>
  <div>
    <small class="text-badgeError text-xs">
      <span v-if="!customError">
        {{ text }}
      </span>
      <span v-else @click="goToRoute" v-html="getCustomErrorMessage"></span>
    </small>
  </div>
</template>

<script>
import router from "@/router";
import {mapGetters} from "vuex";

const EMAIL = 'EMAIL';
const EMAIL_EXISTS = 'EMAIL_EXISTS';
const PARTNER_CODE = 'PARTNER_CODE';
const PARTNER_CODE_NOT_EXISTS = 'PARTNER_CODE_NOT_EXISTS';

export default {
  name: "BaseError",
  props: {
    text: String,
    customError: String,
	  fieldValue: [String, Object, Number],
  },
  computed: {
    ...mapGetters('dictionaries', ['postCategoriesData']),

    getCustomErrorMessage() {
      if(this.customError === EMAIL) {
        return this.trans('MESSAGE:EmailRegisterError')
      }
      if(this.customError === PARTNER_CODE) {
        return this.trans('MESSAGE:PartnerCodeRequired')
      }
	    if(this.customError === PARTNER_CODE_NOT_EXISTS) {
		    return this.trans('MESSAGE:PartnerCodeNotExists')
	    }
	    if(this.customError === EMAIL_EXISTS) {
		    return this.trans('MESSAGE:EmailAlreadyAddedOrError')
	    }
    }
  },

  methods: {
    goToRoute() {
      if(this.customError === EMAIL) {
        let contact = '';
        if(this.postCategoriesData) {
          contact = this.postCategoriesData.find(el => el.slug === 'contact');
        }
        router.push({name: 'post-show', params: {postCategory: contact.slug, slug: contact.onlyPostSlug}, query: {registration: true, email: this.fieldValue}})
      }
      if(this.customError === PARTNER_CODE) {
        router.push({name: 'post-show', params: {postCategory: contact.slug, slug: contact.onlyPostSlug}, query: {registration: true, email: this.fieldValue}})
      }
    }
  }
}
</script>

<style lang="scss">

.emailError {
	text-decoration: underline;
}

.emailError:hover {
  opacity: theme('opacity.50');
  cursor: pointer;
}

</style>