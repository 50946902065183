<template>
  <div class="flex" :class="{'mt-0': noMargin, 'mt-5': !noMargin, 'flex-row': row, 'flex-col': !row}">
    <label class="text-s text-gray-800 mb-2 font-bold" v-if="label">
      {{ label }}
    </label>
    <label v-if="comment" class="text-xs text-gray-500 mb-2">
      {{ comment }}
    </label>
    <slot>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'FieldWrapper',
  props: {
    label: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
    },
    noMargin: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
