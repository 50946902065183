<template>
  <ValidationProvider :rules="rules" :name="field" v-slot="{ errors }">
    <div>
      <t-select v-if="!onlyNumeric"
        type="select"
        :id="experienceSelect ? 'experiencesSelect' : `select_${placeholder}`"
        :value="value"
        :variant="expVariant"
        :options="options"
        :placeholder="placeholder"
        :textAttribute="textAttr ? textAttr === 'nameOrg' ? trans(textAttr) : textAttr : name"
        :valueAttribute="valueAttr ? valueAttr : slugValue ? 'slug' : 'id'"
        :disabled="disabled"
        @input="update"
      ></t-select>
      <t-select v-else
        type="select"
        :variant="variant"
        :value="value"
        :options="options"
        :placeholder="placeholder"
        @input="update"
      ></t-select>
      <BaseError :text="errors[0]" />
    </div>
  </ValidationProvider>
</template>

<script>
import BaseError from "@/utils/validation/components/BaseError";
import {mapGetters} from "vuex";


export default {
  name: 'SelectField',
  components: {BaseError},
  data() {
    return {
      expVariant: null,
    }
  },
  props: {
    value: [Array, String, Number, Object, Boolean],
    fieldType: String,
    field: String,
    rules: String,
    placeholder: String,
    options: [Array, Object],
    slugValue: Boolean,
    onlyNumeric: Boolean,
    name: {
      type: String,
      default: 'name',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textAttr: String,
    valueAttr: String,
    variant: String,
    experienceSelect: Boolean,
  },
  computed: {
    ...mapGetters('dictionaries', ['ratesData']),
  },
  watch: {
    value: {
      handler(val) {
        if(this.experienceSelect) {
          this.expVariant = this.ratesData.find(el => el.id === Number(val)).slug;
        }
      }
    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
  },


}
</script>

<style lang="scss">
#experiencesSelect {
  option[value="1"] {
    background: theme('colors.badgeSuccessPale');
  }
  option[value="2"] {
    background: theme('colors.badgeInfoPale');
  }
  option[value="3"] {
    background: theme('colors.badgeErrorPale');
  }
  option[value="4"] {
    background: theme('colors.badgeDefaultPale');
  }
}
</style>
